define('due-dashboard/components/due-combo-btn', ['exports', 'ember'], function (exports, _ember) {

  /**
   * @author Youba SALMI <youba@diduenjoy.com>
   * @summary {{due-combo-btn}} combo button
   *
   * @module
   * @argument {boolean} showMain - boolean to display or not the main button
   * @argument {array} options - array of objects to display in the dropdown
   * @argument {boolean} showArrow - show/hide the arrow
   * @argument {boolean} enableSearch - enable and show search field
   * @argument {string} dpSize - trigger button size
   * @argument {string} dpIntent - trigger button intent
   * @argument {string} dpIcon - trigger button icon
   * @argument {string} dpText - trigger button text
   * @argument {string} dpAttribute - name of the property to display
   * @argument {string} [containerSelector] - DOM selector of the container that shoud enclosure the dropdown
   * @argument {number} [dpMaxHeight] - maximum height of the dropdown in pixels
   * @argument {promise} [searchPromise] - search promise
   * @argument {function} [triggerSearch] - handler of search action
   * @argument {function} triggerAction - handler of select action
   * @example <caption>Full usage example of {{due-dropdown}} component</caption>
   * Usage :
   * ```hbs
   * {{due-combo-btn
   *   showMain=false
   *   dpSize='medium'
   *   dpIntent='secondary'
   *   dpIcon='tio-user_add'
   *   dpText='digest.add_subscribers'
   *   containerSelector='.subscriber-modal'
   *   dpMaxHeight=200
   *   enableSearch=true
   *   options=dropdownAccounts
   *   dpAttribute='email'
   *   searchPromise=search_promise
   *   triggerAction=(action 'addSubscriber')
   *   triggerSearch=(action 'searchAccounts')
   * }}
   * ```
   */

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var run = _ember['default'].run;
  var $ = _ember['default'].$;
  exports['default'] = Component.extend({
    classNames: ['due-combo-btn'],
    classNameBindings: ['combinedCombo', 'customClass'],
    triggerClientX: 0,
    triggerClientY: 0,
    triggerHeight: 0,
    openDropdown: false,

    init: function init() {
      this._super.apply(this, arguments);
      $(window).on('resize', run.bind(this, this._setTriggerClientX));
    },

    didRender: function didRender() {
      this._super.apply(this, arguments);
      this._setTriggerClientX();
    },

    combinedCombo: computed('showMain', 'options', function () {
      return this.get('showMain') && this.get('options.length') > 1;
    }),

    mainButton: computed('options.firstObject', 'showMain', function () {
      return this.get('showMain') ? this.get('options.firstObject') : null;
    }),

    dpOptions: computed('showMain', 'options', function () {
      var options = this.get('options');
      if (this.get('showMain')) {
        options.shift();
      }
      return options;
    }),

    dpBtText: computed('mainButton', 'dpText', function () {
      return this.get('mainButton') ? null : this.get('dpText');
    }),

    dpBtIntent: computed('mainButton', 'dpIntent', function () {
      return this.get('mainButton') ? this.get('mainButton.intent') : this.get('dpIntent');
    }),

    dpBtSize: computed('mainButton', 'dpSize', function () {
      return this.get('mainButton') ? this.get('mainButton.size') : this.get('dpSize');
    }),

    displayAttribute: computed('dpAttribute', function () {
      return this.get('dpAttribute') ? this.get('dpAttribute') : 'text';
    }),

    _setTriggerClientX: function _setTriggerClientX() {
      var trigger = this.element ? this.element.querySelector('.trigger-btn') : null;
      if (trigger) {
        this.set('triggerClientY', trigger.offsetTop);
        this.set('triggerClientX', trigger.getBoundingClientRect().x);
        this.set('triggerHeight', trigger.getBoundingClientRect().height);
      }
    },

    actions: {
      triggerAction: function triggerAction(_triggerAction, action) {
        if (this.get('closeOnSelect') !== false) {
          this.send('closeDropdown');
        }
        _triggerAction(action);
      },

      triggerSearch: function triggerSearch(_triggerSearch, value) {
        _triggerSearch(value);
      },

      openDropdown: function openDropdown() {
        this.toggleProperty('openDropdown');
        return false;
      },

      closeDropdown: function closeDropdown() {
        this.set('openDropdown', false);
      },

      openMultilevelDropdown: function openMultilevelDropdown(onToggle) {
        if (onToggle) onToggle();
      },

      triggerAndClose: function triggerAndClose(triggerAction, actionName) {
        this.send('closeDropdown');
        triggerAction(actionName);
      },

      clickInsideButton: function clickInsideButton(state) {
        var previousState = this.get('openDropdown');
        var callback = this.get('closeDropDownCallback');

        this.set('openDropdown', state);
        if (callback && previousState) {
          callback();
        }
      }
    }
  });
});